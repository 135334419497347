import styled from 'styled-components'

export const WrapperTablePortlet = styled.div`
  div.switchButton {
    border-radius: unset !important;
  }
`

export const StyledLink = styled.a`
  color: #009ef7;
  cursor: pointer;

  &:hover {
    color: '0.2s ease, background-color 0.2s ease';
  }
`
