import React from 'react'
import { PageTitle } from '../../assets/layout/core'

export const Dashboard: React.FC = () => {
  return (
    <div className="container-fluid">
      <PageTitle>Informações Gerenciais</PageTitle>
    </div>
  )
}

export default Dashboard
