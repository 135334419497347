export const Menus = [
  {
    id: 1,
    method: 'GET',
    name: 'Meus dados',
    url: '/profile',
    permission: true,
    type: 'front'
  },
  {
    id: 2,
    method: 'GET',
    name: 'Alunos',
    url: '/commercial/students',
    permission: true,
    type: 'front'
  },
  {
    id: 3,
    method: 'GET',
    name: 'Contratações',
    url: '/commercial/contracts',
    permission: true,
    type: 'front'
  }
]
