import DataTable from 'components/DataTable'
import { LinkContainer } from 'components/DataTable/style'
import Modal from 'components/Modal'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import ViewInfoLabel from 'components/ViewInfoLabel'
import moment from 'moment'
import { MenuTable, MenuTableRow } from 'pages/Menus/View/styles'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Alert } from '../../../../../components/Alert'
import Container from '../../../../../components/Container'
import { useLoading } from '../../../../../hooks/loading'
import { useToast } from '../../../../../hooks/toast'
import api from '../../../../../services/api'
import { FormMenuFood } from '../components/Form'
import { IComposition, useMenuFoodContext } from '../contexts'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { headerMenuDishes, headerMenuInstitutions } from '../domain/headers'
import { nameActions } from '../domain/info'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { StyledLink } from './styles'

const MenuFoodView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [menuFood, setMenuFood] = useState<IMenuFoodData | null>(null)
  const [modalTypeOpen, setModalTypeOpen] = useState<TModalType>('closed')
  const [childId, setChildId] = useState<number>()
  const { setComposition, insertDefaultInstitutions } = useMenuFoodContext()
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const searchParams = useRef([{ menu_food_id: `${id}` }])
  const searchParametersAuditLog = useRef([
    { entity: 'MenuFood', entity_id: id }
  ])
  const refModal = useRef(null)

  const handleClickOnClose = () => {
    setModalTypeOpen('closed')
  }

  const handleClickOnOpenModalCreate = () => {
    const defaultComposition = menuFood.menu_dishes.map(
      (menuDish): IComposition => {
        return {
          id: uuidv4(),
          dish_id: menuDish.dish_id,
          dish_amount: menuDish.dish_amount,
          dish_name: menuDish.dish.name,
          dish_type_id: menuDish.dish.dish_type_id,
          is_default_dish: menuDish.is_default_dish
        }
      }
    )

    if (defaultComposition.length > 0) {
      setComposition(defaultComposition)
    }
    insertDefaultInstitutions(menuFood.menu_institutions)
    setModalTypeOpen('create')
  }
  const handlerOnClickButtonEditInCurrentRow = (
    menuFoodToUpdate: IMenuFoodData
  ) => {
    setChildId(menuFoodToUpdate.id)
    const defaultComposition = menuFoodToUpdate.menu_dishes.map(
      (menuDish): IComposition => {
        return {
          id: uuidv4(),
          dish_id: menuDish.dish_id,
          dish_amount: menuDish.dish_amount,
          dish_name: menuDish.dish.name,
          dish_type_id: menuDish.dish.dish_type_id,
          is_default_dish: menuDish.is_default_dish
        }
      }
    )

    if (defaultComposition.length > 0) {
      setComposition(defaultComposition)
    }
    insertDefaultInstitutions(menuFoodToUpdate.menu_institutions)

    setModalTypeOpen('update')
  }

  const handlerOnClickButtonRemoveInCurrentRow = ({
    id,
    name
  }: {
    id: number
    name: string
  }) => {
    setIsActiveAlert({
      id,
      name: `cardápio ${name}`,
      isActive: true
    })
  }

  const { disableLoading, activeLoading } = useLoading()

  useEffect(() => {
    async function loadMenuFood(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IMenuFoodData>(apiList(id))
        const { data } = response
        setMenuFood(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o item',
          description:
            'Houve um error ao carregar o item, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadMenuFood()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Cardápio removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover o cardápio, pois ele está registrado em um plano.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Item removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse item, pois ele está registrado num menu.'
      })
    }
  }

  const getInitialValues = (): IMenuFoodData & {
    idUpdate: number
  } => {
    if (modalTypeOpen === 'create') {
      return {
        idUpdate: Number(id),
        ...menuFood,
        end_validity: undefined,
        start_validity: moment(menuFood?.end_validity, 'DD/MM/YYYY')
          .add(1, 'd')
          .toDate()
      }
    } else {
      const menuFoodToUpdate = menuFood?.menu_foods_related?.find(
        menuFood => menuFood.id === childId
      )
      return {
        idUpdate: Number(childId),
        ...menuFoodToUpdate
      }
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={menuFood?.menu_food_type?.name}
              label="Tipo"
            />
            <ViewInfoLabel
              col={3}
              description={`${menuFood?.start_validity.slice(
                0,
                10
              )} -  ${menuFood?.end_validity.slice(0, 10)}`}
              label="Vigência"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={12}
              description={menuFood?.description}
              label="Descrição"
            />
          </div>
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={menuFood?.has_items === true ? 'Sim' : 'Não'}
              label="Possui itens"
            />
            <ViewInfoLabel
              col={3}
              description={menuFood?.is_active === true ? 'Sim' : 'Não'}
              label="Ativo"
            />
            <ViewInfoLabel
              col={3}
              description={menuFood?.created_at}
              label="Cadastrado em:"
            />

            <div className="separator my-5" />
            <div className="row">
              <div className="col-md-12">
                <Tabs>
                  <Tab key={0} title="Itens">
                    <div className="portlet light">
                      <div className="card-header p-0">
                        <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                          Listagem
                        </div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          format={{ orderBy: 'id' }}
                          source="menu_food_dishes"
                          entity="MenuFoodDish"
                          onlyView
                          headers={headerMenuDishes}
                          searchParameters={searchParams.current}
                          actions={[
                            {
                              name: 'view',
                              title: 'Visualizar',
                              spanIcon: 'fa fa-search',
                              linkTo: dish =>
                                `/commercial/dishes/view/${dish.id}`,
                              style: { margin: '0 5px' }
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab key={1} title="Instituições">
                    <div className="portlet light">
                      <div className="card-header p-0">
                        <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                          Listagem
                        </div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          format={{ orderBy: 'id' }}
                          source="commercial/menuFoodInstitutions"
                          entity="MenuFoodInstitution"
                          onlyView
                          headers={headerMenuInstitutions}
                          searchParameters={searchParams.current}
                          actions={[
                            {
                              name: 'view',
                              title: 'Visualizar',
                              spanIcon: 'fa fa-search',
                              linkTo: institution =>
                                `/commercial/institutions/view/${institution.id}`,
                              style: { margin: '0 5px' }
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </Tab>
                  {menuFood?.menu_foods_related && (
                    <Tab key={2} title="Vigências">
                      <div>
                        <div className="card-header p-0 d-flex - align-items-center">
                          <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                            Listagem
                          </div>
                          <div className="">
                            <StyledLink
                              onClick={() => handleClickOnOpenModalCreate()}
                            >
                              <span className="fa fa-plus" /> Adicionar
                            </StyledLink>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <MenuTable
                            id="kt_datatable"
                            className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                          >
                            <thead className="border-bottom border-end">
                              <tr>
                                <th>Nome</th>
                                <th>Período</th>
                                <th>Ativo</th>
                                <th>Cadastrado em</th>
                                <th className="text-center">Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {menuFood?.menu_foods_related.length === 0 && (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    Nenhum registro encontrado
                                  </td>
                                </tr>
                              )}

                              {menuFood?.menu_foods_related
                                ?.sort((a, b) => {
                                  return (
                                    moment(
                                      b.start_validity,
                                      'DD/MM/YYYY'
                                    ).valueOf() -
                                    moment(
                                      a.start_validity,
                                      'DD/MM/YYYY'
                                    ).valueOf()
                                  )
                                })
                                .map(menuFoodRelated => {
                                  const period = `${moment(
                                    menuFoodRelated?.start_validity,
                                    'DD/MM/YYYY'
                                  ).format('DD/MM/YYYY')} -  ${moment(
                                    menuFoodRelated?.end_validity,
                                    'DD/MM/YYYY'
                                  ).format('DD/MM/YYYY')}`

                                  return (
                                    <MenuTableRow key={menuFoodRelated.id}>
                                      <td>{menuFoodRelated.name}</td>
                                      <td>{period}</td>
                                      <td>
                                        {menuFoodRelated.is_active === true
                                          ? 'Sim'
                                          : 'Não'}
                                      </td>
                                      <td>{menuFoodRelated.created_at}</td>
                                      <td>
                                        <LinkContainer className="gap-2">
                                          <a
                                            href={`/commercial/menuFoods/view/${menuFoodRelated.id}`}
                                            className="text-decoration-none"
                                            style={{
                                              border: 'none',
                                              color: '#009EF7',
                                              cursor: 'pointer',
                                              background: 'none'
                                            }}
                                          >
                                            <span className="fa fa-search" />
                                          </a>
                                          <button
                                            onClick={() =>
                                              handlerOnClickButtonEditInCurrentRow(
                                                menuFoodRelated
                                              )
                                            }
                                            style={{
                                              border: 'none',
                                              color: '#009EF7',
                                              background: 'none'
                                            }}
                                          >
                                            <span className="fa fa-edit" />
                                          </button>
                                          {/* <button
                                            onClick={() =>
                                              handlerOnClickButtonRemoveInCurrentRow(
                                                {
                                                  id: +menuFoodRelated.id,
                                                  name: menuFoodRelated.name
                                                }
                                              )
                                            }
                                            className="text-decoration-none"
                                            style={{
                                              border: 'none',
                                              color: '#009EF7',
                                              background: 'none'
                                            }}
                                          >
                                            <span className="fa fa-remove" />
                                          </button> */}
                                        </LinkContainer>
                                      </td>
                                    </MenuTableRow>
                                  )
                                })}
                            </tbody>
                          </MenuTable>
                        </div>
                      </div>
                    </Tab>
                  )}

                  <Tab
                    title="Histórico"
                    key={menuFood?.menu_foods_related ? 3 : 2}
                  >
                    <div className="portlet light">
                      <div
                        className="portlet-title mt-3 d-flex justify-content-between"
                        style={{ height: '34.4px' }}
                      >
                        <div className="caption fs-5 fw-bold">Listagem</div>
                        <div className="tools"></div>
                      </div>
                      <div className="portlet-body form">
                        <DataTable
                          entityId={id}
                          format={{ orderBy: '' }}
                          source="auditLogs"
                          entity="AuditLog"
                          searchParameters={searchParametersAuditLog.current}
                        />
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalTypeOpen !== 'closed'}
        pageTitle={modalTypeOpen === 'create' ? 'Adicionar' : 'Editar'}
        style={{
          stylesModal: {
            height: '100%'
          }
        }}
        Children={
          <FormMenuFood
            typeForm={modalTypeOpen === 'create' ? 'create' : 'update'}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(id)
            }}
            original_menu_food_id={+id}
            initialValues={getInitialValues()}
            isChild
            setMenuFood={setMenuFood}
          />
        }
      />

      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${menuFood?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(menuFood?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default MenuFoodView
