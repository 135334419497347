/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiDeletePlanFrequency, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'

import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import Modal from 'components/Modal'
import { useUpdateDataTable } from 'hooks/dataTable'
import { FormPlanFrequency } from '../components/FormPlanFrequency'
import { MenuTableRow } from 'pages/Menus/View/styles'
import { LinkContainer, MenuTable } from './styles'
import { currencyMask } from 'utlis/mask'

export interface IPlanFrequencyDefaultValues {
  id: number
  plan_name: string
  frequency: string
  periodicity: TPeriodicity
  is_active: boolean
  value: string
}

const PlanView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [plan, setPlan] = useState<IPlan | null>(null)
  const [currentPlanFrequencyUpdate, setCurrentPlanFrequencyUpdate] =
    useState<IPlanFrequencyDefaultValues>()

  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const searchParametersAuditLog = useRef([{ entity: 'Plan', entity_id: id }])

  const { disableLoading, activeLoading } = useLoading()
  const refModal = useRef(null)

  const [modalTypeOpen, setModalTypeOpen] = useState<TModalType>('closed')
  const [idParentCreate, setIdParentCreate] = useState<number>()

  const handleClickOnClose = () => {
    setModalTypeOpen('closed')
    updateDataTable()
  }

  const handlerOnClickButtonEditInCurrentRow = (
    currentValue: IPlanFrequency
  ) => {
    const { id, is_active, frequency, payament_form } = currentValue
    const payament_form_obj = JSON.parse(payament_form) as {
      value: string
      periodicity: TPeriodicity
    }

    setCurrentPlanFrequencyUpdate({
      id,
      plan_name: plan.name,
      is_active,
      frequency,
      periodicity: payament_form_obj.periodicity,
      value: payament_form_obj.value
    })
    setModalTypeOpen('update')
  }

  const handleClickOnOpenModalCreate = (id: number) => {
    setIdParentCreate(id)
    setModalTypeOpen('create')
  }

  const handlerOnClickButtonRemoveInCurrentRow = ({
    id,
    frequency
  }: IPlanFrequency) => {
    setIsActiveAlert({
      id,
      name: `plano de frequência ${frequency}`,
      isActive: true
    })
  }

  useEffect(() => {
    async function loadPlan(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<IPlan>(apiList(id))
        const { data } = response
        setPlan(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o plano',
          description:
            'Houve um error ao carregar o plano, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadPlan()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeLoading,
    addToast,
    disableLoading,
    history,
    location.pathname,
    updateDataTable
  ])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }

  const handlerClickButtonConfirmAlert = async (frequencyPlanId: string) => {
    try {
      await api.delete(apiDeletePlanFrequency(frequencyPlanId))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      const response = await api.get<IPlan>(apiList(id))
      const { data } = response
      setPlan(data)
      addToast({
        type: 'success',
        title: 'Plano de frequência removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title:
          'Não foi possível remover o plano de frequência, pois ele está registrada num contrato.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Plano removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title:
          'Não é possível remover esse plano, pois ela está registrada num contrato.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="form-group col-md-4">
              <label
                htmlFor="id"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Cód.
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{plan?.id}</p>
            </div>
            <div className="form-group col-md-4">
              <label
                htmlFor="name"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Nome
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{plan?.name}</p>
            </div>
            <div className="form-group col-md-4">
              <label
                htmlFor="cardápío"
                className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
              >
                Cardápio
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {plan?.menu_food.name}
              </p>
            </div>
          </div>
          <div className="form-group col-md-12 mb-5">
            <label
              htmlFor="description"
              className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
            >
              Descrição
            </label>
            <p className="fw-bolder fs-6 text-gray-800">{plan?.description}</p>
          </div>
          <div className="row mb-5">
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="active"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Ativo
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {plan?.is_active === true ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  htmlFor="created"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Cadastrado em
                </label>
                <p className="fw-bolder fs-6 text-gray-800">
                  {plan?.created_at}
                </p>
              </div>
            </div>
          </div>
          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab key={0} title="Frequência dos planos">
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div className="">
                        <a
                          href="#"
                          onClick={() =>
                            handleClickOnOpenModalCreate(Number(id))
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="fa fa-plus" /> Adicionar
                        </a>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <MenuTable
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Frequência</th>
                            <th>Periodicidade</th>
                            <th>Valor</th>
                            <th>Ativo</th>
                            <th>Cadastrado em</th>
                            <th className="text-center">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {plan?.plans_frequency.length === 0 && (
                            <tr>
                              <td colSpan={6} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {plan?.plans_frequency?.map(planFrequency => {
                            const payament_form = JSON.parse(
                              planFrequency.payament_form
                            )
                            const periodicity = payament_form.periodicity
                            const value = payament_form.value
                            return (
                              <MenuTableRow key={planFrequency.id}>
                                <td>{planFrequency.frequency}</td>
                                <td>{periodicity}</td>
                                <td>{currencyMask(value)}</td>
                                <td>
                                  {planFrequency.is_active === true
                                    ? 'Sim'
                                    : 'Não'}
                                </td>
                                <td>{planFrequency.created_at}</td>
                                <td>
                                  <LinkContainer className="gap-2">
                                    <button
                                      onClick={() =>
                                        handlerOnClickButtonEditInCurrentRow(
                                          planFrequency
                                        )
                                      }
                                      style={{
                                        border: 'none',
                                        color: '#009EF7',
                                        background: 'none'
                                      }}
                                    >
                                      <span className="fa fa-edit" />
                                    </button>
                                    <button
                                      onClick={() =>
                                        handlerOnClickButtonRemoveInCurrentRow(
                                          planFrequency
                                        )
                                      }
                                      className="text-decoration-none"
                                      style={{
                                        border: 'none',
                                        color: '#009EF7',
                                        background: 'none'
                                      }}
                                    >
                                      <span className="fa fa-remove" />
                                    </button>
                                  </LinkContainer>
                                </td>
                              </MenuTableRow>
                            )
                          })}
                        </tbody>
                      </MenuTable>
                    </div>
                  </div>
                </Tab>
                <Tab title="Histórico" key={1}>
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalTypeOpen !== 'closed'}
        pageTitle={modalTypeOpen === 'create' ? 'Adicionar' : 'Editar'}
        Children={
          <FormPlanFrequency
            typeForm={modalTypeOpen === 'create' ? 'create' : 'update'}
            plan={{ id: plan?.id, name: plan?.name }}
            isOpenInModal={{
              handleOnClose: handleClickOnClose,
              idParent: Number(idParentCreate)
            }}
            initialValues={
              modalTypeOpen === 'update' && {
                ...currentPlanFrequencyUpdate,
                idUpdate: currentPlanFrequencyUpdate?.id
              }
            }
          />
        }
      />
      <Alert
        message={`Tem certeza que deseja excluir o ${alert.name} ?`}
        onClickCancelButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${plan?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(plan?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default PlanView
