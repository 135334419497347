import PlansList from '../../pages/Commercial/Plans/List'
import PlansUpdate from '../../pages/Commercial/Plans/Update'
import PlansCreate from '../../pages/Commercial/Plans/Create'
import PlansView from '../../pages/Commercial/Plans/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PlansRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/plans" exact component={PlansList} />
    <Route path="/commercial/plans/create" component={PlansCreate} exact />
    <Route path="/commercial/plans/view/:id" component={PlansView} exact />
    <Route path="/commercial/plans/update/:id" component={PlansUpdate} exact />
  </CustomSwitch>
)
