import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import CustomDataTable from '../../../../components/CustomDataTable'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { useAuth } from 'hooks/auth'

const ContractList = (): JSX.Element => {
  const [contracts, setContracts] = useState<IContract[]>([])
  const { user } = useAuth()
  const getContracts = useCallback(async () => {
    const contractsListResponse = await api.get(
      `commercial/contracts/byClient/${user.id}`
    )
    setContracts(contractsListResponse.data)
  }, [user])

  useEffect(() => {
    getContracts()
  }, [getContracts])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <h1>Minhas contratações</h1>
      <hr style={{ margin: '20px 0px' }} />
      <CustomDataTable
        customItems={contracts}
        headers={headers}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: item => `/commercial/contracts/view/${item.id}`
          }
        ]}
      />
    </Container>
  )
}

export default ContractList
